import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Container } from '../../../common'
import Header from '../../includes/Header'
import Footer from '../../includes/Footer'
import equalityIllustration from '../../../../static/illustrations/equality.svg'
import Logo from '../../../../static/logo/logo-white.svg'
import './styles.scss'

const How = ({ goBack, refresh }) => (
	<React.Fragment>
		<Header goBack={goBack} refresh={refresh} siteTitle="Ta6beeq.io | كيف حصلنا على التقدير" />
		<Container className="how-container">
			<img src={equalityIllustration} alt="quality" />
			<div className="right-align">
				<h1>كيف حصلنا على التقدير</h1>
				<p>
					<FormattedMessage id="message.how_we_get_estimation" />
				</p>
				<a className="barmej-btn primary" href="https://barmej.com/?ref=ta6beeq">
					<img src={Logo} alt="Barmej Logo" />
				</a>
			</div>
		</Container>
		<Footer />
	</React.Fragment>
)

export default How

