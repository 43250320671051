import React from 'react'
import { Choice } from '../../../common'
import androidIllustration from '../../../../static/illustrations/android.svg'
import appleIllustration from '../../../../static/illustrations/apple.svg'
import bothIllustration from '../../../../static/illustrations/both.svg'

const App = ({
	nextStep, editStep, data, completed, editSingleStep, formatMessage
}) => {
	const choices = [
		{
			id: 0,
			icon: androidIllustration,
			title: formatMessage({ id: 'message.app_android' }),
			price: formatMessage({ id: 'message.app_android_price' }),
			illustration: 'andoirdIllu'
		},
		{
			id: 1,
			icon: appleIllustration,
			title: formatMessage({ id: 'message.app_ios' }),
			price: formatMessage({ id: 'message.app_ios_price' }),
			illustration: 'appleIllu'
		},
		{
			id: 2,
			icon: bothIllustration,
			title: formatMessage({ id: 'message.app_android_ios' }),
			price: formatMessage({ id: 'message.app_android_ios_price' }),
			illustration: 'bothIllu'
		}
	]
	return (
		<Choice
			editSingleStep={editSingleStep}
			completed={completed}
			nextStep={nextStep}
			editStep={editStep}
			data={data}
			current="1"
			choices={choices}
			question="app_title"
			description="app_description"
		/>
	)
}

export default App
