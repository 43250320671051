import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import { Container } from '../../../common'
import Header from '../../includes/Header'
import Footer from '../../includes/Footer'
import './styles.scss'


const Gift = ({ goBack }) => (
	<StaticQuery
		query={graphql`
			query AboutImageQuery {
					GiftImage: imageSharp(fluid: {originalName: { regex: "/gift.jpg/" }}) {
						fluid(maxWidth: 630 ) {
							...GatsbyImageSharpFluid_tracedSVG
						}
					}
				}
		`}
		render={data => (
			<React.Fragment>
				<Header goBack={goBack} siteTitle="Ta6beeq.io | إلى جنان الخلد يا طارق" />
				<Container className="gift-container">
					<Img fluid={data.GiftImage.fluid} alt="RIP Tariq" />
					<div className="right-align">
						
						<h1>إهداء للصديق طارق</h1>
						<p>
							<FormattedMessage id="message.condolences" />
						</p>
					</div>
				</Container>
				<Footer />
			</React.Fragment>
		)}
	/>
)

export default Gift
