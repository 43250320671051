import React from 'react'
import { injectIntl } from 'react-intl'
import {
	Intro,
	App,
	Auth,
	Files,
	Revenue,
	Rate,
	Connected,
	Look,
	Icon
} from '../../Quiz'
import Estimate from '../Estimate'
import How from '../How'
import Gift from '../Gift'

const Step = ({
	step,
	barmejData,
	firstStep,
	nextStep,
	refresh,
	goToStep,
	editStep,
	completed,
	editSingleStep,
	complete,
	intl: { formatMessage }
}) => {
	const totalPrice = (prop) => {
		let total = 0
		for (let i = 0, len = barmejData.length; i < len; i++) {
			total += barmejData[i][prop]
		}
		return total
	}

	const priceTotal = totalPrice('price')

	if (typeof window !== 'undefined') {
		window.scrollTo(0, 0)
	}
	switch (step) {
	case 0:
		return <Intro firstStep={firstStep} />
	case 1:
		return (
			<App
				editSingleStep={value => editSingleStep(value)}
				completed={completed}
				data={barmejData}
				editStep={value => editStep(value)}
				nextStep={submittedData => nextStep(submittedData)}
				formatMessage={formatMessage}
			/>
		)
	case 2:
		return (
			<Auth
				editSingleStep={value => editSingleStep(value)}
				completed={completed}
				score={priceTotal}
				data={barmejData}
				editStep={value => editStep(value)}
				goToStep={value => goToStep(value)}
				nextStep={submittedData => nextStep(submittedData)}
				formatMessage={formatMessage}
			/>
		)
	case 3:
		return (
			<Files
				editSingleStep={value => editSingleStep(value)}
				completed={completed}
				score={priceTotal}
				data={barmejData}
				editStep={value => editStep(value)}
				goToStep={value => goToStep(value)}
				nextStep={submittedData => nextStep(submittedData)}
				formatMessage={formatMessage}
			/>
		)
	case 4:
		return (
			<Revenue
				editSingleStep={value => editSingleStep(value)}
				completed={completed}
				score={priceTotal}
				data={barmejData}
				editStep={value => editStep(value)}
				goToStep={value => goToStep(value)}
				nextStep={submittedData => nextStep(submittedData)}
				formatMessage={formatMessage}
			/>
		)
	case 5:
		return (
			<Rate
				editSingleStep={value => editSingleStep(value)}
				completed={completed}
				score={priceTotal}
				data={barmejData}
				editStep={value => editStep(value)}
				goToStep={value => goToStep(value)}
				nextStep={submittedData => nextStep(submittedData)}
				formatMessage={formatMessage}
			/>
		)
	case 6:
		return (
			<Connected
				editSingleStep={value => editSingleStep(value)}
				completed={completed}
				score={priceTotal}
				data={barmejData}
				editStep={value => editStep(value)}
				goToStep={value => goToStep(value)}
				nextStep={submittedData => nextStep(submittedData)}
				formatMessage={formatMessage}
			/>
		)
	case 7:
		return (
			<Look
				editSingleStep={value => editSingleStep(value)}
				completed={completed}
				score={priceTotal}
				data={barmejData}
				editStep={value => editStep(value)}
				goToStep={value => goToStep(value)}
				nextStep={submittedData => nextStep(submittedData)}
				formatMessage={formatMessage}
			/>
		)
	case 8:
		return (
			<Icon
				editSingleStep={value => editSingleStep(value)}
				completed={completed}
				score={priceTotal}
				data={barmejData}
				editStep={value => editStep(value)}
				goToStep={value => goToStep(value)}
				nextStep={submittedData => nextStep(submittedData)}
				formatMessage={formatMessage}
			/>
		)
	case 9:
		return (
			<Estimate
				complete={complete}
				seeHow={value => goToStep(value)}
				score={priceTotal}
				data={barmejData}
				refresh={refresh}
			/>
		)
	case 10:
		return <How goBack={value => goToStep(value)} />
	case 11:
		return <Gift goBack={value => goToStep(value)} />
	default:
		return null
	}
}

export default injectIntl(Step)
