import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Container, Button } from '../../../common'
import Header from '../../includes/Header'
import Footer from '../../includes/Footer'
import startIllustration from '../../../../static/illustrations/start.svg'
import leftArrow from '../../../../static/icons/arrow-left.svg'
import './styles.scss'

const Intro = ({ firstStep }) => (
	<React.Fragment>
		<Header step="0" siteTitle="Ta6beeq.io | احسب تكلفة تطوير تطبيقك خلال دقائق" />
		<Container className="start">
			<img src={startIllustration} alt="start" />
			<h1>
				<FormattedMessage id="message.intro_message" />
			</h1>
			<p>
				<FormattedMessage id="message.intro_description" />
			</p>
			<Button className="start-button" onClick={firstStep}>
				<span>ابدأ الآن</span>
				<img src={leftArrow} alt="start now" />
			</Button>
		</Container>
		<Footer step="0" />
	</React.Fragment>
)

export default Intro 
