import React from 'react'
import { Choice } from '../../../common'
import dunnoIllustration from '../../../../static/illustrations/dunno.svg'
import freeIllustration from '../../../../static/illustrations/free.svg'
import payIllustration from '../../../../static/illustrations/pay.svg'
import prepayIllustration from '../../../../static/illustrations/prepay.svg'

const Revenue = ({
	nextStep, goToStep, editStep, data, score, formatMessage
}) => {
	const choices = [
		{
			id: 0,
			icon: dunnoIllustration,
			title: formatMessage({ id: 'message.revenue_no_idea' }),
			price: formatMessage({ id: 'message.revenue_no_idea_price' }),
			illustration: 'dunnoIllu'
		},
		{
			id: 1,
			icon: freeIllustration,
			title: formatMessage({ id: 'message.revenue_free' }),
			price: formatMessage({ id: 'message.revenue_free_price' }),
			illustration: 'freeIllu'
		},
		{
			id: 2,
			icon: payIllustration,
			title: formatMessage({ id: 'message.revenue_in_app_payment' }),
			price: formatMessage({ id: 'message.revenue_in_app_payment_price' }),
			illustration: 'payIllu'
		},
		{
			id: 3,
			icon: prepayIllustration,
			title: formatMessage({ id: 'message.revenue_pre_payment' }),
			price: formatMessage({ id: 'message.revenue_pre_payment_price' }),
			illustration: 'prepayIllu'
		}
	]
	return (
		<Choice
			nextStep={nextStep}
			goToStep={goToStep}
			editStep={editStep}
			previous
			data={data}
			score={score}
			current="4"
			choices={choices}
			question="revenue_title"
			description="revenue_description"
		/>
	)
}

export default Revenue
