import React from 'react'
import { Choice } from '../../../common'
import dunnoIllustration from '../../../../static/illustrations/dunno.svg'
import declineIllustration from '../../../../static/illustrations/decline.svg'
import connectedIllustration from '../../../../static/illustrations/connected.svg'

const Connected = ({
	nextStep, goToStep, editStep, data, score, completed, editSingleStep, formatMessage
}) => {
	const choices = [
		{
			id: 0,
			icon: dunnoIllustration,
			title: formatMessage({ id: 'message.connected_no_idea' }),
			price: formatMessage({ id: 'message.connected_no_idea_price' }),
			illustration: 'dunnoIllu'
		},
		{
			id: 1,
			icon: declineIllustration,
			title: formatMessage({ id: 'message.connected_decline' }),
			price: formatMessage({ id: 'message.connected_decline_price' }),
			illustration: 'declineIllu'
		},
		{
			id: 2,
			icon: connectedIllustration,
			title: formatMessage({ id: 'message.connected_approve' }),
			price: formatMessage({ id: 'message.connected_approve_price' }),
			illustration: 'connectedIllu'
		}
	]
	return (
		<Choice
			editSingleStep={editSingleStep}
			completed={completed}
			nextStep={nextStep}
			goToStep={goToStep}
			editStep={editStep}
			previous
			data={data}
			score={score}
			current="6"
			choices={choices}
			question="connected_title"
			description="connected_description"
		/>
	)
}

export default Connected
