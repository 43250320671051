import React from 'react'
import { withState, compose, lifecycle } from 'recompose'
import { FormattedMessage } from 'react-intl'
import { Container, Icon } from '../../../common'
import Header from '../../includes/Header'
import Footer from '../../includes/Footer'
import moneyIllustration from '../../../../static/illustrations/money.svg'
import arrowDown from '../../../../static/icons/arrow-down.svg'
import leftArrow from '../../../../static/icons/arrow-left.svg'
import Logo from '../../../../static/logo/logo.svg'
import './styles.scss'

const Estimate = ({
	refresh, score, seeHow, data, visible, setVisible
}) => (
	<React.Fragment>
		<Header refresh={refresh} pricing siteTitle="Ta6beeq.io | احسب تكلفة تطوير تطبيقك خلال دقائق" />
		<Container className="estimate">
			<div className="estimate-price">
				<img src={moneyIllustration} alt="حساب تكلفة التطبيق" />
				<h2>سيكلفك اﻟﺘﻄﺒﻴﻖ: {score}$</h2>
				<div className="pricing flexed justify-between">
					<div className="flexed select" onClick={() => setVisible(!visible)}>
						<p>عرض الأجوبة</p>
						<img src={arrowDown} alt="Arrow down" />
					</div>
					<button type="button" className="backward" onClick={() => seeHow(10)}>كيف حصلنا على التقدير</button>
				</div>
			</div>
			{visible && (
				<div className="choices-taken">
					{data.map(({
						step, question, response, price, illustration
					}) => {
						const stepNumber = parseInt(step, 10)
						return (
							<div key={step} className="card-choice">
								<div className="content">
									<h5>
										<FormattedMessage id={`message.${question}`} />
									</h5>
									<div className="details">
										<p>{response}</p>
										<p>${price}</p>
										<button type="button" className="backward" onClick={() => seeHow(stepNumber)}>(تغيير)</button>
									</div>
								</div>
								<div className="illustration">
									<Icon response={illustration} />
								</div>
							</div>
						)
					})}
				</div>
			)}
			<div className="save-money">
				<h2>احتفظ بمالك واﺑﺪأ بتعلم اﻟﺒﺮﻣﺠﺔ مجاناً</h2>
				<p>برمج.كوم يتيح لك تعلم البرمجة وتحويل أفكارك إلى واقع</p>
				<div className="barmej-buttons">
					<a className="barmej-btn primary" href="https://barmej.com/?ref=ta6beeq">
						<span>إﺑﺪأ التعلم</span>
						<img src={leftArrow} alt="Start now" />
					</a>
					<a className="barmej-btn outlined" href="https://barmej.com/?ref=ta6beeq">
						<img src={Logo} alt="Barmej Logo" />
					</a>
				</div>
			</div>
		</Container>
		<Footer gift={() => seeHow(11)} />
	</React.Fragment>
)

const enhance = compose(
	withState('visible', 'setVisible', false),
	lifecycle({
		componentDidMount() {
			this.props.complete()
		}
	})
)

export default enhance(Estimate)
