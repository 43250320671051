import React from 'react'
import { Choice } from '../../../common'
import simpleIllustration from '../../../../static/illustrations/simple.svg'
import stockIllustration from '../../../../static/illustrations/stock.svg'
import goodIllustration from '../../../../static/illustrations/good.svg'

const Look = ({
	nextStep, goToStep, editStep, data, score, completed, editSingleStep, formatMessage
}) => {
	const choices = [
		{
			id: 0,
			icon: simpleIllustration,
			title: formatMessage({ id: 'message.look_simple' }),
			price: formatMessage({ id: 'message.look_simple_price' }),
			illustration: 'goodIllu'
		},
		{
			id: 1,
			icon: stockIllustration,
			title: formatMessage({ id: 'message.look_stock' }),
			price: formatMessage({ id: 'message.look_stock_price' }),
			illustration: 'simpleIllu'
		},
		{
			id: 2,
			icon: goodIllustration,
			title: formatMessage({ id: 'message.look_beautiful' }),
			price: formatMessage({ id: 'message.look_beautiful_price' }),
			illustration: 'stockIllu'
		}
	]
	return (
		<Choice
			editSingleStep={editSingleStep}
			completed={completed}
			nextStep={nextStep}
			goToStep={goToStep}
			editStep={editStep}
			previous
			data={data}
			score={score}
			current="7"
			choices={choices}
			question="look_title"
			description="look_description"
		/>
	)
}

export default Look
