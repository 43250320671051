import React from 'react'
import { Choice } from '../../../common'
import dunnoIllustration from '../../../../static/illustrations/dunno.svg'
import declineIllustration from '../../../../static/illustrations/decline.svg'
import iconIllustration from '../../../../static/illustrations/icon.svg'

const Icon = ({
	nextStep, goToStep, editStep, data, score, completed, editSingleStep, formatMessage
}) => {
	const choices = [
		{
			id: 0,
			icon: dunnoIllustration,
			title: formatMessage({ id: 'message.icon_no_idea' }),
			price: formatMessage({ id: 'message.icon_no_idea_price' }),
			illustration: 'dunnoIllu'
		},
		{
			id: 1,
			icon: declineIllustration,
			title: formatMessage({ id: 'message.icon_decline' }),
			price: formatMessage({ id: 'message.icon_decline_price' }),
			illustration: 'declineIllu'
		},
		{
			id: 2,
			icon: iconIllustration,
			title: formatMessage({ id: 'message.icon_approve' }),
			price: formatMessage({ id: 'message.icon_approve_price' }),
			illustration: 'iconIllu'
		}
	]
	return (
		<Choice
			editSingleStep={editSingleStep}
			completed={completed}
			nextStep={nextStep}
			goToStep={goToStep}
			editStep={editStep}
			previous
			data={data}
			score={score}
			current="8"
			choices={choices}
			question="icon_title"
			description="icon_description"
		/>
	)
}

export default Icon
