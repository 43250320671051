import React from 'react'
import { compose, withStateHandlers } from 'recompose'
import { Layout } from '../common'
import Step from '../components/LandingParts/Step'

const IndexPage = ({
	step,
	barmejData,
	firstStep,
	nextStep,
	refresh,
	goToStep,
	editStep,
	completed,
	editSingleStep,
	complete,
}) => {
	console.log(barmejData)
	return (
		<Layout>
			<Step
				step={step}
				barmejData={barmejData}
				firstStep={firstStep}
				nextStep={nextStep}
				refresh={refresh}
				goToStep={goToStep}
				editStep={editStep}
				completed={completed}
				editSingleStep={editSingleStep}
				complete={complete}
			/>
		</Layout>
	)
}

const enhance = compose(
	withStateHandlers(
		({ initialState = 0 }) => ({
			step: initialState, barmejData: [], checked: false, completed: false
		}),
		{
			complete: () => () => ({ completed: true }),
			firstStep: ({ step }) => () => ({ step: step + 1 }),
			nextStep: ({ step, barmejData }) => (value, completed) => ({
				barmejData: [...barmejData, value],
				step: step + 1,
				completed
			}),
			editStep: ({ step, barmejData }) => value => ({
				barmejData: barmejData
					.map(item => (item.step === value.step && value.checked ? value : item)),
				step: step + 1
			}),
			editSingleStep: ({ barmejData }) => value => ({
				barmejData: barmejData
					.map(item => (item.step === value.step && value.checked ? value : item)),
				step: 9,
				completed: true
			}),
			goToStep: () => value => ({ step: value }),
			refresh: () => () => ({ step: 0, barmejData: [], completed: false })
		}
	)
)

export default enhance(IndexPage)
